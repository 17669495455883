import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"

const Partnerships = () => (
  <Layout>
    <SEO title="Partnerships" />

    <h1>I'm a page, please make me</h1>
  </Layout>
)
export default Partnerships
